<template>
	<v-layout column>
		<ApplicationsTable
			:action="openApplicationSubscription"
			action-icon="add"
			:headers="headers"
			:items="applications"
			:loading="loading"
			:title="$t('application.list_unused')"
		/>
		<SubscriptionDialog v-model="genericDialog" :application="application" />
		<BeeyeSubscriptionDialog
			:open="beeyeDialog"
			:value="application && application.identifier == 'beeye' ? application : null"
			@close="closeApplicationSubscription()"
		/>
	</v-layout>
</template>

<script>
import ApplicationsModuleGuard from '@/mixins/ModulesGuards/Applications/ApplicationsModuleGuard'
import SignService from '@/services/SignatureDocument/SignatureDocumentService'

export default {
	name: 'UnusedApplicationsTable',
	components: {
		ApplicationsTable: () => ({
			component: import('@/components/Applications/ApplicationsTable')
		}),
		BeeyeSubscriptionDialog: () => ({
			component: import('@/components/Applications/BeeyeSubscriptionDialog')
		}),
		SubscriptionDialog: () => ({
			component: import('@/components/Applications/SubscriptionDialog')
		})
	},
	mixins: [ApplicationsModuleGuard],
	data: function () {
		return {
			application: null,
			applications: [],
			beeyeDialog: false,
			genericDialog: false,
			headers: [
				{ align: 'center', sortable: false, text: this.$t('application.headers.logo'), value: 'logo', width: '100px' },
				{ align: 'center', text: this.$t('application.headers.name'), value: 'title' },
				{ align: 'center', text: this.$t('application.headers.description'), value: 'desc' },
				{ align: 'center', sortable: false, text: this.$t('application.headers.info'), value: 'info', width: '180px' },
				{ align: 'center', sortable: false, text: this.$t('application.headers.add'), value: 'add', width: '204px' }
			],
			isFormLoading: false,
			isFormValid: false,
			loading: false,
			params: {}
		}
	},
	watch: {
		accountingFirmId: {
			handler: 'loadApplications'
		}
	},
	mounted: function () {
		this.loadApplications()
	},
	methods: {
		closeApplicationSubscription: function () {
			this.beeyeDialog = false
			this.genericDialog = false
			this.neoExpertDialog = false
			this.application = null
			this.params = {}
		},
		getModuleEventsActionsMapping: function () {
			return [
				{ event: this.events.APPLICATION_SUBSCRIPTION_CREATED, action: this.loadApplications },
				{ event: this.events.APPLICATION_SUBSCRIPTION_DELETED, action: this.loadApplications }
			]
		},
		loadApplications: function () {
			this.loading = true
			return this.service
				.listApplications(this.accountingFirmId)
				.then(applications => {
					this.applications = applications
				})
				.finally(() => {
					this.loading = false
				})
		},
		openApplicationSubscription: function (application) {
			this.application = application

			if (this.application.identifier == 'beeye') {
				this.beeyeDialog = true
				return Promise.resolve()
			} else if (this.application.identifier == 'jesignexpert') {
				return this.subscribeToJSE()
			} else {
				return this.service.listAccountingFirmApplicationParameters(this.accountingFirmId, this.application.id)
					.then(parameters => {
						if (parameters.length == 0) {
							return this.subscribeToApplication()
						}
						this.application.params_accounting_firm = parameters
						this.genericDialog = true
					})
			}
		},
		snackbarSuccess: function (applicationTitle) {
			this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('application.actions.created', { name: applicationTitle }))
		},
		subscribeToApplication: function () {
			if (!this.isFormValid && this.application.params_accounting_firm) {
				return
			}

			this.isFormLoading = true
			return this.service
				.createSubscription(this.accountingFirmId, this.application.id, this.params)
				.then(result => {
					this.snackbarSuccess(this.application.title)
					this.eventBus.emit(this.events.APPLICATION_SUBSCRIPTION_CREATED, result)
					this.closeApplicationSubscription()
				})
				.finally(() => {
					this.isFormLoading = false
				})
		},
		subscribeToJSE: function () {
			return SignService.checkIfApplicationIsAdded(this.accountingFirmId)
				.then(response => response.data.data)
				.then(response => {
					if (response.status == 'completed') {
						return this.service
							.createSubscription(this.accountingFirmId, this.application.id, { officeToken: response.token })
							.then(subscription => {
								this.snackbarSuccess(this.application.title)
								this.eventBus.emit(this.events.APPLICATION_SUBSCRIPTION_CREATED, subscription)
							})
							.finally(() => {
								this.closeApplicationSubscription()
							})
					} else {
						return SignService.getAccountingFirmTokenUrl(this.accountingFirmId)
							.then(response => response.data.data)
							.then(response => {
								window.open(response['auth-url'].link)

								const checkIfAuthenticatedInterval = setInterval(async () => {
									const authStatus = await SignService.checkIfApplicationIsAdded(this.accountingFirmId)
									if (authStatus.data.data.status === 'completed') {
										clearInterval(checkIfAuthenticatedInterval)
										this.service
											.createSubscription(this.accountingFirmId, this.application.id, { officeToken: authStatus.data.data.token })
											.then(subscription => {
												this.snackbarSuccess(this.application.title)
												this.eventBus.emit(this.events.APPLICATION_SUBSCRIPTION_CREATED, subscription)
											})
											.finally(() => {
												this.closeApplicationSubscription()
											})
									}
								}, 5000)
							})
					}
				})
		}
	}
}
</script>
