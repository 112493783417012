// ============================================================================
// SignatureDocumentService
// ------------------------
// SignatureDocument module related services
// ============================================================================

// -------
// Imports
// -------
import API from '@/apis/SignatureDocumentApi'

// -------
// Exports
// -------
export default {
	getAccountingFirmTokenUrl: function (accountingFirmId) {
		return API.getAccountingFirmTokenUrl(accountingFirmId)
	},
	checkIfApplicationIsAdded: function (accountingFirmId) {
		return API.checkIfApplicationIsAdded(accountingFirmId)
	}
}
