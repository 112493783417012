import { Config } from '@/services/ConfigService'
import { Configurator } from '@/plugins/axios/Configurator'

const axios = Configurator.defaultConfiguration(Config.VUE_APP_ROOT_API + '/api/')

// FIXME: provider should be set dynamically
const provider = 'jesignexpert'

const getAccountingFirmTokenUrl = accountingFirmId => {
	return axios.get(`oauth/providers/${provider}/auth-url`, {
		params: {
			'accounting-firm-id': accountingFirmId
		}
	})
}

const checkIfApplicationIsAdded = accountingFirmId => {
	return axios.get(`oauth/providers/${provider}/status/accounting-firm-id/${accountingFirmId}`)
}

export default {
	getAccountingFirmTokenUrl,
	checkIfApplicationIsAdded
}
